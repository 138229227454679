import React from 'react'
import Layout from '../components/Layout'
import GenericHeader from "../components/header_section/GenericHeader";

const NotFoundPage = () => (
  <Layout>
      <GenericHeader title={`You just hit a route that doesn't exist...`}
      />
      <br/>
      <br/>
  </Layout>
)

export default NotFoundPage
